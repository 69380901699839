import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SemanticSIZES } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { WorkshopSession } from '../../types/workshop.types';

type Props = {
  session: WorkshopSession;
  video: { uri: string };
  label?: string;
};

export function trackWebinarPayload(
  session: WorkshopSession,
  live: { uri: string },
): Record<string, any> {
  return {
    userId: store.userId,
    entityType: session.collection,
    entityCollection: session.collection,
    entityId: session._id,
    workshopId: session.workshopId || session._id,
    workshopSessionId: session.workshopId ? session._id : undefined,
    roomUri: live.uri,
  };
}

export const ExternalLiveButton: FC<Props> = ({ session, video, label, ...buttonProps }) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const onWebinarAccess = () => {
    trackEvent(eventTags.WEBINAR_ENTER, trackWebinarPayload(session, video));
  };
  return (
    <Button
      as="a"
      href={video.uri}
      target="_blank"
      primary
      content={label || t('workshops.workshop.access-webinar')}
      onClick={onWebinarAccess}
      {...buttonProps}
    />
  );
};
