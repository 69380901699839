import { useState } from 'react';
import Gallery from 'react-photo-gallery';

import { bem } from '../../../core/design/bem';
import Images from '../../../utils/Images';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';
import ImageLightbox from '../FilesBlock/components/ImageLightbox';
import { BlockProps, ContainerProps, Image } from '../types';
import './PhotoGalleryBlock.scss';

const css = bem('PhotoGalleryBlock');

type ImageProps = {
  _id: string;
  image?: Image;
  title?: string;
};

type PhotoProps = {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  photo?: Record<string, any>;
};

type PhotoGalleryBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  images?: ImageProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  galleryProps?: Record<string, any>;
} & typeof PhotoGalleryBlockDefaultProps;

const PhotoGalleryBlockDefaultProps = {
  container: {},
  className: '',
  galleryProps: {},
  images: [],
};

function formatPhotos(images: ImageProps[]) {
  return images
    .filter(({ image }) => image?.uri)
    ?.map(({ _id: imageId, image }) => {
      return {
        key: imageId,
        src: image?.uri,
        cdn: Images.cdn(image),
        srcSet: [200, 300, 500, 800, 1024, 1600].map(
          (size) => `${Images.maxWidth(image, size)} ${size}w`,
        ),
        width: image?.width || 1920,
        height: image?.height || 1080,
      };
    });
}

const PhotoGalleryBlock = ({
  _id,
  className,
  container,
  galleryProps,
  images,
}: PhotoGalleryBlockProps): JSX.Element | null => {
  const [{ showLightbox, index: lightboxIndex }, setLightbox] = useState({
    showLightbox: false,
    index: 0,
  });
  const photos = formatPhotos(images);

  const openLightbox = (index: number) => setLightbox({ showLightbox: true, index });
  const closeLightbox = () => setLightbox({ showLightbox: false, index: 0 });

  if (photos.length === 0) return null;

  const renderImage = ({ index, photo }: PhotoProps) => {
    return (
      <div key={index}>
        <CdnImage {...photo} />
      </div>
    );
  };

  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      {showLightbox && (
        <ImageLightbox
          images={images.map(({ image, title }) => ({ ...image, title }))}
          currentIndex={lightboxIndex}
          onClose={closeLightbox}
        />
      )}
      <Gallery
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        photos={photos}
        {...galleryProps}
        renderImage={renderImage}
        onClick={(_evt, data) => openLightbox(data.index)}
      />
    </BlockContainer>
  );
};

PhotoGalleryBlock.defaultProps = PhotoGalleryBlockDefaultProps;

export default PhotoGalleryBlock;
