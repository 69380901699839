/*eslint-disable consistent-return*/
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import trim from 'lodash/trim';
import { parse, stringify } from 'query-string';
import { ParsedUrlQuery, parse as qsParse } from 'querystring';

import { UserType } from '../core/users/users.types';
import { urlJoin } from './stringUtils';

export function getUrlParameters(customUrl?: string): ParsedUrlQuery {
  const url = customUrl || window.location.href;
  const index = url.indexOf('?');
  if (index === -1) return {};
  return qsParse(url.slice(index + 1));
}

const urlPrefixes = {
  qa: ['https://live.appcraft.events'],
  feedback: ['https://api.appcraft.events/grade', 'https://app.sandbox.appcraft.events/grade'],
  wordCloud: ['https://static.appcraft.events/word-cloud'],
  quiz: ['https://static.appcraft.events/autonomous-quiz', 'https://quiz.appcraft.events/client'],
  appcraft: ['https://static.appcraft.events/live'],
  registration: [
    'https://api.appcraft.events/registrations',
    'https://api.sandbox.appcraft.events/registrations',
  ],
};

export function textToBase64Url(content: string, mimetype?: string): string {
  return `data:${mimetype || ''};base64,${btoa(content)}`;
}

const getUrlType = (url: string): string => {
  let urlType = '';
  Object.entries(urlPrefixes).forEach(([type, entries]) => {
    if (entries.find((entry) => startsWith(url.trim(), entry))) {
      urlType = type;
    }
  });
  return urlType;
};

const isAppCraftUrl = (urlType: string) => {
  return urlType === 'appcraft';
};
const isRegistrationUrl = (urlType: string, iframeType?: string) => {
  return urlType === 'registration' || iframeType === 'form';
};

const isInteractionUrl = (urlType: string) => {
  return ['qa', 'feedback', 'quiz', 'wordCloud'].includes(urlType);
};

export const urlJoinParams = (url: string, params: Record<string, any> | string): string => {
  if (!params) return url;

  if (typeof params === 'object') {
    if (!Object.keys(params).length) return url;
    return urlJoinParams(url, stringify(params));
  }
  const join = url.includes('?') ? '&' : '?';
  return `${url}${join}${params}`;
};

export const extractUTM = (queryString: string): Record<string, any> => {
  if (!queryString) return {};

  const params = parse(queryString);
  return pickBy(params, (_v, k) => startsWith(k, 'utm_'));
};

const urlJoinUnescaped = (url: string, params: Record<string, any>) => {
  return urlJoinParams(url, params).replace(/%7B/g, '{').replace(/%7D/g, '}');
};

function getUrlWithPrivateKey(url: string, user: UserType, isAnonymous?: boolean) {
  if (!url) return url;

  if (url.includes('/k/{privateKey}')) {
    if (!isAnonymous && user?.privateKey) {
      return url;
    }
    return url.replace('/k/{privateKey}', '');
  }

  if (!isAnonymous && user?.privateKey) {
    return urlJoin([url, 'k/{privateKey}']);
  }

  return url;
}

export const generateIframeUrl = (
  url: string,
  user: UserType,
  options: { isAnonymous?: boolean; iframeType?: string; lang?: string },
): string => {
  const { isAnonymous, iframeType, lang } = options;
  // eslint-disable-next-line no-restricted-globals
  const type = getUrlType(url);

  if (isRegistrationUrl(type, iframeType) && typeof window !== 'undefined') {
    // eslint-disable-next-line no-restricted-globals
    const params = extractUTM(window.location.search);

    const urlWithPrivateKey = getUrlWithPrivateKey(url, user, isAnonymous);
    if (urlWithPrivateKey?.indexOf('?lang=') !== -1) {
      // if url already contains lang
      return urlJoinUnescaped(urlWithPrivateKey, params);
    }
    return urlJoinUnescaped(urlWithPrivateKey, { ...params, lang });
  }
  if (isAppCraftUrl(type)) {
    // Auto-inject params if needed...
    if (!url.includes('userId=')) {
      return urlJoinUnescaped(url, {
        userId: '{_id}',
        firstName: '{firstName}',
        lastName: '{lastName}',
        thumbnail: '{thumbnail.uri}',
      });
    }
  }
  if (isInteractionUrl(type)) {
    if (isAnonymous) {
      return url;
    }
    return url.includes('userId=')
      ? url
      : urlJoinUnescaped(url, {
          userId: '{_id}',
          firstName: '{firstName}',
          lastName: '{lastName}',
        });
  }

  return url;
};

const formatRedirectUrl = (redirectPath?: string) => {
  return redirectPath === '/' ? undefined : redirectPath;
};

const removeEmptyParams = (params: Record<string, unknown>): Record<string, unknown> => {
  return pickBy(params, (v) => v);
};

export const computeSearchParam = (redirectPath?: string): string => {
  const urlParameters = pick(getUrlParameters(), [
    'u',
    'email',
    'modal',
    'logout_reason',
    'success_redirect',
  ]);
  const {
    logout_reason: logoutReason,
    success_redirect: successRedirect,
    ...restParams
  } = urlParameters;

  return `?${stringify(
    removeEmptyParams({
      success_redirect:
        logoutReason !== 'disconnect' && (successRedirect || formatRedirectUrl(redirectPath)),
      logout_reason: logoutReason,
      ...restParams,
    }),
  )}`;
};

export function makeLanguagePage(newLang: string): string {
  const { lang, basename } = window.__DATA__;
  const curBasename = trim(basename, '/'); // Trim to be tolerant to missing slashes
  const newBasename = curBasename.replace(lang, newLang);
  return window.location.href.replace(`/${curBasename}`, `/${newBasename}`);
}

export function handleChangeLanguage(_e: unknown, { value }: { value: string }): void {
  window.location.href = makeLanguagePage(value);
}
